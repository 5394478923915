@font-face {
  font-family: "iconfont"; /* Project id 4122066 */
  src: url('iconfont.woff2?t=1686725677957') format('woff2'),
       url('iconfont.woff?t=1686725677957') format('woff'),
       url('iconfont.ttf?t=1686725677957') format('truetype');
}

.iconfont1 {
  font-family: "iconfont" !important;
  font-size: 3.8rem;
  color: white;
  cursor: pointer;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconfont2 {
  font-family: "iconfont" !important;
  font-size: 3.5rem;
  color: white;
  cursor: pointer;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconfont3 {
  font-family: "iconfont" !important;
  font-size: 4.4rem;
  color: gray;
  cursor: pointer;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconfont4 {
  font-family: "iconfont" !important;
  font-size: 4.4rem;
  color: white;
  cursor: pointer;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconfont5 {
  font-family: "iconfont" !important;
  font-size: 3.8rem;
  color: gray;
  cursor: not-allowed;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-tuichu:before {
  content: "\e60a";
}

.icon-zanting:before {
  content: "\e600";
}

.icon-bofang:before {
  content: "\e8a3";
}

.icon-beijingyinle-:before {
  content: "\e61e";
}

